import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import { useTranslation } from "react-i18next";
import EventsPopup from "./EventsPopup";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import noDataIm from "../../images/no-data.png";
import { API } from "../../Constants/Global";

const Events = () => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [events, setEvents] = useState(null);
  const [myEvents, setMyEvents] = useState(null);
  const [event, setEvent] = useState(null);

  function handleCloseModal(event, data) {
    setOpenPopup(false);
  }

  const getEvents = () => {
    const lng = localStorage.getItem("i18nextLng");
    const tkn = localStorage.getItem("token");
    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;

    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}events/getevents?SubscriberId=${subscriberId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setEvents(response.data.Data);
          setIsLoading(false);
        } else {
         // toast.error(response.data.Message);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const getMyEvents = () => {
    const lng = localStorage.getItem("i18nextLng");
    const tkn = localStorage.getItem("token");
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
    axios
      .get(
        `${API}events/getsubscriberevents?SubscriberId=${subscriberId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setMyEvents(response.data.Data);
          setIsLoading(false);
        } else {
         // toast.error(response.data.Message);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getEvents();
    getMyEvents();
  }, []);

  const navigate = useNavigate();
  return isLoading ? (
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <div className="page-header">
            <h3 className="page-title col-md-8">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-calendar-multiple"></i>
              </span>{" "}
              {t("Events")}{" "}
            </h3>
            <h3 className="page-title col-md-4">{t("MyEvents")}</h3>
          </div>

          <div className="row">
            <div className="col-md-8 leftToRightdiv">
              <div className="row">
                {events && events.length != 0 ? (
                  events?.map((obj, index) => {
                    return (
                      <div className="col-md-6">
                        <div className="ft-recipe">
                          <div className="ft-recipe__thumb">
                            <img src={obj.Images[0]} alt="Event image" />
                          </div>
                          <div className="ft-recipe__content">
                            <header className="content__header">
                              <div className="row-wrapper">
                                <span
                                  className="recipe-title"
                                  onClick={() => {
                                    setEvent(obj);
                                    setOpenPopup(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {i18n.language === "en"
                                    ? obj.EventNameEng
                                    : obj.EventNameAra}
                                </span>
                                <div className="user-rating"></div>
                              </div>
                            </header>
                            <p className="description">
                              {i18n.language === "en"
                                ? obj.LocationEng
                                : obj.LocationAra}
                            </p>
                            <header className="content__header mt-2">
                              <div className="">
                                <span className="">{obj.Organizer}</span>
                              </div>
                            </header>

                            <div className="row">
                              {obj.EventDates.split(",").map((ob, ind) => {
                                return (
                                  <span className="col-md-5 mt-2 bg-gray-200 rounded-full px-3 py-1 text-sm  text-gray-700 mr-1">
                                    {ob}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="empty-state"
                    style={{ backgroundColor: "transparent", paddingTop: "6%", margin:"0 auto" }}
                  >
                    <div className="empty-state__content">
                      <div className="empty-state__icon">
                        <img src={noDataIm} alt="" />
                      </div>
                      <div className="empty-state__message">
                        {t("NoRecordS")}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              {/* <div style={{ position: "fixed", width: "25%" }}> */}
              <div>
                <div
                  className="card-profile"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className=" pt-0 pt-md-0"
                    style={{ padding: "0px 10px 0px 10px" }}
                  >
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ paddingRight: "0", paddingLeft: "0" }}
                      >
                        <div className="pricing-table red">
                          {myEvents && myEvents.length != 0 ? (
                            myEvents?.map((obj, index) => {
                              return (
                                <div
                                  className="pricing-features"
                                  style={{ textAlign: "left", marginTop: "0" }}
                                >
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="title">
                                        <p className="myEvents">
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              color: "#012690",
                                            }}
                                          >
                                            <i className="mdi mdi-account-check"></i>
                                          </span>
                                          <span>
                                            {i18n.language === "en"
                                              ? obj.EventNameEng
                                              : obj.EventNameAra}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="title">
                                        <p className="myEvents">
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              color: "#012690",
                                            }}
                                          >
                                            <i className="mdi mdi-map-marker-circle"></i>
                                          </span>{" "}
                                          {i18n.language === "en"
                                            ? obj.LocationEng
                                            : obj.LocationAra}{" "}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="title">
                                        <p className="myEvents">
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              color: "#012690",
                                            }}
                                          >
                                            <i className="mdi mdi-account-check"></i>
                                          </span>
                                          <span>{obj.Organizer}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="title">
                                        <p className="myEvents">
                                          {" "}
                                          <span
                                            style={{
                                              fontSize: "16px",
                                              color: "#012690",
                                            }}
                                          >
                                            <i className="mdi mdi mdi-timer"></i>
                                          </span>{" "}
                                          {obj.EventDates.split(",").map(
                                            (ob, ind) => {
                                              return (
                                                <span className="col-md-5 mt-2 bg-gray-200 rounded-full px-3 py-1 text-sm  text-gray-700 mr-1">
                                                  {ob}
                                                </span>
                                              );
                                            }
                                          )}
                                          <span style={{ cursor: "pointer" }}>
                                            {" "}
                                            <i
                                              style={{ verticalAlign: "sub" }}
                                              className="mdi mdi mdi-dots-horizontal"
                                            ></i>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="title">
                                        {" "}
                                        {obj.EventStatusId === 1 && (
                                          <p
                                            className="myEvents"
                                            style={{
                                              color: "#01258c",
                                              textTransform: "uppercase",
                                              textAlign: "right",
                                            }}
                                          >
                                            {" "}
                                            <span
                                              style={{
                                                fontSize: "16px",
                                                color: "#012690",
                                              }}
                                            >
                                              <i className="mdi mdi-check-circle"></i>
                                            </span>{" "}
                                            {t("Attending")}
                                          </p>
                                        )}
                                        {obj.EventStatusId === 2 && (
                                          <p
                                            className="myEvents"
                                            style={{
                                              color: "#01258c",
                                              textTransform: "uppercase",
                                              textAlign: "right",
                                            }}
                                          >
                                            {" "}
                                            <span
                                              style={{
                                                fontSize: "16px",
                                                color: "#012690",
                                              }}
                                            >
                                              <i className="mdi mdi-close-circle"></i>
                                            </span>{" "}
                                            {t("NotAttending")}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="title"></div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              className="empty-state"
                              style={{
                                backgroundColor: "transparent",
                                paddingTop: "6%",
                              }}
                            >
                              <div className="empty-state__content">
                                <div className="empty-state__icon">
                                  <img src={noDataIm} alt="" />
                                </div>
                                <div className="empty-state__message">
                                  {t("NoRecordS")}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {openPopup && (
              <EventsPopup
                event={event}
                open={openPopup}
                onCloseModal={handleCloseModal}
              />
            )}
          </div>
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Events;
