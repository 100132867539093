import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import MobilePopup from "./MobilePopup";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import RenewalPopup from "./RenewalPopup";
import HistoryPopup from "./HistoryPopup";
import SubscribePopup from "./SubscribePopup";

import maleIcon from "../../images/male.jpeg";
import femaleIcon from "../../images/female.jpeg";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { API } from "../../Constants/Global";
import FreezeRequestPopup from "./FreezeRequestPopup";

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [openRenewalPopup, setOpenRenewalPopup] = useState(false);
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [openSubscribePopup, setOpenSubscribePopup] = useState(false);
  const [openFreezePopup, setOpenFreezePopup] = useState(false);
  const [interestList, setInterestList] = useState([]);
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      EmergencyContacts: emergencyContacts,
    },
  });

  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  function handleCloseModal(event, data) {
    RenewalPopup(false);
    HistoryPopup(false);
  }
  const navigate = useNavigate();


  const onPageLoad = async () => {

    let config = {
      headers: {
        APICODE: token,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };

    await axios
      .get(
        `${API}public/getinterests`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setInterestList(response.data.Data);
          setIsLoading(false);
        } else {
          setInterestList([]);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));


    await axios
      .get(`${API}subscriber/profile`, config)
      .then((response) => {
        if (response.data.isSuccess) {
          localStorage.setItem("user", JSON.stringify(response.data.Data));
          //const user = JSON.parse(localStorage.getItem("user"));
          //setUser(response.data.Data);
          setUser((prev) => ({
            ...prev,
            ...response.data.Data
          }));
          setEmergencyContacts(JSON.parse(user?.EmergencyContacts));
          let checkEmergencyContact = JSON.parse(response.data.Data?.EmergencyContacts).length;
          setValue("EmergencyContacts",
            checkEmergencyContact > 0 ? JSON.parse(response.data.Data?.EmergencyContacts)
              : [{
                name: "",
                mobile: "",
                email: "",
              }]
          );

        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => console.log(error));



  }



  function handleCloseModal(event, data) {
    setOpenPopup(false);
    setOpenRenewalPopup(false);
    setOpenHistoryPopup(false);
    setOpenSubscribePopup(false);
    setOpenFreezePopup(false);
  }

  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }


  const DateOfBirth = watch("DOB");


  function gmod(n, m) {
    return ((n % m) + m) % m;
  }

  function kuwaiticalendar(adjust) {
    var today = DateOfBirth;
    if (adjust) {
      let adjustmili = 1000 * 60 * 60 * 24 * adjust;
      let todaymili = today.getTime() + adjustmili;
      let today = new Date(todaymili);
    }
    let day = today?.getDate();
    let month = today?.getMonth();
    let year = today?.getFullYear();
    let m = month + 1;
    let y = year;
    if (m < 3) {
      y -= 1;
      m += 12;
    }

    let a = Math.floor(y / 100);
    let b = 2 - a + Math.floor(a / 4);
    if (y < 1583) b = 0;
    if (y == 1582) {
      if (m > 10) b = -10;
      if (m == 10) {
        b = 0;
        if (day > 4) b = -10;
      }
    }

    let jd =
      Math.floor(365.25 * (y + 4716)) +
      Math.floor(30.6001 * (m + 1)) +
      day +
      b -
      1524;

    b = 0;
    if (jd > 2299160) {
      a = Math.floor((jd - 1867216.25) / 36524.25);
      b = 1 + a - Math.floor(a / 4);
    }
    let bb = jd + b + 1524;
    let cc = Math.floor((bb - 122.1) / 365.25);
    let dd = Math.floor(365.25 * cc);
    let ee = Math.floor((bb - dd) / 30.6001);
    day = bb - dd - Math.floor(30.6001 * ee);
    month = ee - 1;
    if (ee > 13) {
      cc += 1;
      month = ee - 13;
    }
    year = cc - 4716;

    if (adjust) {
      var wd = gmod(jd + 1 - adjust, 7) + 1;
    } else {
      wd = gmod(jd + 1, 7) + 1;
    }

    let iyear = 10631 / 30;
    let epochastro = 1948084;
    let epochcivil = 1948085;

    let shift1 = 8.01 / 60;

    let z = jd - epochastro;
    let cyc = Math.floor(z / 10631);
    z = z - 10631 * cyc;
    let j = Math.floor((z - shift1) / iyear);
    let iy = 30 * cyc + j;
    z = z - Math.floor(j * iyear + shift1);
    let im = Math.floor((z + 28.5001) / 29.5);
    if (im == 13) im = 12;
    let id = z - Math.floor(29.5001 * im - 29);

    var myRes = new Array(8);

    myRes[0] = day; //calculated day (CE)
    myRes[1] = month - 1; //calculated month (CE)
    myRes[2] = year; //calculated year (CE)
    myRes[3] = jd - 1; //julian day number
    myRes[4] = wd - 1; //weekday number
    myRes[5] = id; //islamic date
    myRes[6] = im - 1; //islamic month
    myRes[7] = iy; //islamic year

    function digits_count(n) {
      var count = 0;
      if (n >= 1) ++count;

      while (n / 10 >= 1) {
        n /= 10;
        ++count;
      }

      return count;
    }

    if (digits_count(myRes[6]) === 1) {
      myRes[6] = "0" + myRes[6];
    }
    if (digits_count(myRes[5]) === 1) {
      myRes[5] = "0" + myRes[5];
    }
    const hijriDate = myRes[6] + "-" + myRes[5] + "-" + myRes[7];
    return hijriDate;
  }

  let hijriDOB = kuwaiticalendar();

  hijriDOB = hijriDOB === "NaN-NaN-NaN" ? "" : hijriDOB;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "EmergencyContacts",
  });

  const onSubmit = (data) => {
    let dateOfBirth = convert(data.DOB);
    data.DOB = dateOfBirth;
    data.SubscriberId = user?.SubscriberId;
    let stringifiedEmerConts = JSON.stringify(data.EmergencyContacts);
    data.EmergencyContacts = stringifiedEmerConts;
    let config = {
      headers: {
        APICODE: token,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}subscriber/updatesubscriber`,
        data,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success(response.data.Message);
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => console.log(error));
  };

  let fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  useEffect(() => {
    fakeRequest().then(() => {
      onPageLoad();
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-account"></i>
              </span>{" "}
              {t("Profile")}
            </h3>
          </div>

          <div className="">
            <div className=" mt--7">
              <div className="row">
                <div className="col-xl-4 order-xl-2 mb-5 mb-xl-0">
                  <div className="card card-profile shadow">
                    <div className="row justify-content-center">
                      <div className="col-lg-3 order-lg-2">
                        <div className="card-profile-image">
                          <input
                            type="file"
                            className="custom-file-input fileCustome"
                          />
                          <img
                            src={user?.GenderId == 1 ? maleIcon : femaleIcon}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                      <div className="d-flex justify-content-between"></div>
                    </div>
                    <div
                      className="card-body pt-0 pt-md-4"
                      style={{ padding: "20px" }}
                    >
                      <div
                        className="text-center mt-md-5"
                        style={{ paddingTop: "40px" }}
                      >
                        <h3>{user?.Name}</h3>
                        <div>
                          <i className="ni education_hat mr-2"></i>
                          {user?.SubscriberNo}
                        </div>
                        <div>
                          <i className="ni education_hat mr-2"></i>
                          {user?.Mobile}
                        </div>
                        <div>
                          <i className="ni education_hat mr-2"></i>
                          {user?.Email}
                        </div>

                        <div className="row">
                          <div className="col-md-12 mb-5"></div>
                          {user?.SubscriberTypeId > 0 && <div className="col-md-12" >
                            <div className="pricing-table red">
                              <h2>{t("Subscription")}</h2>

                              <div
                                className="pricing-features"
                                style={{ textAlign: "left" }}
                              >
                                <div className="feature">
                                  {t("Type")}
                                  <span>
                                    {i18n.language === "en"
                                      ? user?.SubscriberNameEng
                                      : user?.SubscriberNameAra}
                                  </span>
                                </div>
                                <div className="feature">
                                  {t("ValidFrom")}
                                  <span>{user?.StartDate}</span>
                                </div>
                                <div className="feature">
                                  {t("ValidTo")}
                                  <span>{user?.EndDate}</span>
                                </div>
                                <div className="feature">
                                  {t("Fees")}
                                  <span>SAR. {user?.Fees}</span>
                                </div>
                              </div>

                              {user?.NoOfRemainingDays <= 0 && <div className="price-tag" >
                                <span className="symbol" style={{ color: "red" }}>Expired</span>
                              </div>}
                              {user?.NoOfRemainingDays > 0 && <div className="price-tag" >
                                <span className="amount">
                                  {user?.NoOfRemainingDays}</span>

                                <span className="symbol" style={{ color: "red" }}> Days Left</span>
                              </div>}

                              {user?.NoOfRemainingDays <= 30 && <button
                                className="btn btn-block btn-lg btn-gradient-primary mt-0"
                                style={{ padding: "5px", width: "45%", borderRadius: "unset" }}
                                onClick={() => setOpenRenewalPopup(true)}
                              >
                                <i
                                  className="mdi mdi-account-card-details"
                                ></i>{" "}
                                {t("Renewal")}
                              </button>}
                              &nbsp;
                              {
                              user?.MaxDiff > 0 && 
                              <button
                                className="btn btn-outline-primary btn-fw"
                                style={{ padding: "5px", width: "45%", borderRadius: "unset" }}
                                onClick={() => setOpenFreezePopup(true)}
                              >
                                <i
                                  className="mdi mdi-account-card-details"
                                ></i>{" "}
                               {t("Freeze")}
                              </button>}
                              <button
                                className="btn btn-custom btn-link btn-fw mt-30"
                                style={{ paddingLeft: "20px", width: "100%" }}
                                onClick={() => setOpenHistoryPopup(true)}
                              >

                                <i
                                  className="mdi mdi-account-card-details"
                                  style={{ paddingRight: "10px" }}
                                ></i>{" "}
                                {t("SubscriptionHistory")}

                              </button>
                            </div>
                          </div>}
                          {user?.SubscriberTypeId == 0 &&
                            <div className="col-md-12" >
                            <div className="pricing-table red">
                              <h4>{t("NotSubscriber")}</h4>
                          <button
                                className="btn btn-block btn-lg btn-gradient-primary mt-0"
                                style={{ padding: "5px", width: "50%", borderRadius: "unset" }}
                                onClick={() => setOpenSubscribePopup(true)}
                              >
                                <i
                                  className="mdi mdi-account-card-details"

                                ></i>{" "}
                                {t("Subscribe")}
                              </button>
                              </div>
                              </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 order-xl-2 mt-5 mb-xl-0">
                      <h6 className="heading-small text-muted mb-4">
                        {t("ChangeMobNum")}
                      </h6>
                      <div className="text-center positionPhone">
                        <h2 className="phoneChange">
                          <i
                            className="mdi mdi-phone"
                            style={{ color: "#fff" }}
                          ></i>
                        </h2>
                      </div>
                      <div className="card card-profile shadow">
                        <div
                          className="card-body pt-0 pt-md-0"
                          style={{ padding: "22px" }}
                        >
                          <div className="text-center mt-5 red">
                            <h3 className="price-tag">{user?.Mobile}</h3>
                            <span style={{ color: "#0126905c" }}>
                              {t("ClickToChange")}
                            </span>
                          </div>
                          <div className="mt-md-3">
                            <div
                              className="col-md-12"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-fw"
                                onClick={() => setOpenPopup(true)}
                              >
                                {t("ChangeMobNum")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 order-xl-1">
                  <div className="card shadow">
                    <div className="card-body">
                      <form>
                        <h6 className="heading-small text-muted mb-4">
                          {t("PersonalDetails")}
                        </h6>
                        <div className="pl-lg-0">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {t("NationalID")} &nbsp;{" "}
                                  <font color="red">*</font>
                                </label>
                                <input
                                  type="text"
                                  id="input-username"
                                  className="form-control form-control-alternative"
                                  defaultValue={user?.NationalID}
                                  {...register("NationalID", {
                                    required: {
                                      value: true,
                                      message: t("IDRequired"),
                                    },
                                    pattern: {
                                      value: /^[1|2]\d{9}$/,
                                      message: t("IDInvalid"),
                                    },
                                  })}
                                />
                                {errors.NationalID &&
                                  errors.NationalID.type == "required" && (
                                    <p
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {t("IDRequired")}
                                    </p>
                                  )}
                                {errors.NationalID &&
                                  errors.NationalID.type == "pattern" && (
                                    <p
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {t("IDInvalid")}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  {t("Name")} &nbsp; <font color="red">*</font>
                                </label>
                                <input
                                  type="text"
                                  id="input-email"
                                  className="form-control form-control-alternative"
                                  defaultValue={user?.Name}
                                  {...register("Name", {
                                    required: {
                                      value: true,
                                      message: t("NameRequired"),
                                    },
                                  })}
                                />
                                {errors.Name &&
                                  errors.Name.type == "required" && (
                                    <p
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {t("NameRequired")}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {t("DateOfBirth")} &nbsp;{" "}
                                  <font color="red">*</font>
                                </label>
                                <div className="wrap-input100 validate-input m-t-10">
                                  <Controller
                                    name="DOB"
                                    control={control}
                                    defaultValue={new Date(user?.DOB)}
                                    rules={{
                                      required: {
                                        value: true,
                                        message: t("DOBRequired"),
                                      },
                                    }}
                                    render={({ field }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DesktopDatePicker
                                        disabled={true}
                                          views={["year", "month", "day"]}
                                          value={field.value}
                                          onChange={(e) => field.onChange(e)}
                                          className="form-control form-control-alternative"
                                          maxDate={new Date()}
                                          renderInput={(params) => (
                                            <TextField className="w3" {...params} />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                  {errors.DOB && errors.DOB.type == "required" && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {t("DOBRequired")}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="form-group"
                                style={{
                                  marginTop: "35px",
                                  color: "#01269066",
                                }}
                              >
                                ( {t("HijriDate")} -{" "}
                                {DateOfBirth ? hijriDOB : ""}
                                {!DateOfBirth && user?.DOB && user?.DOB_Hijri} )
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  htmlFor="cars"
                                  className="form-control-label"
                                >
                                  {t("Nationality")}
                                </label>
                                <select
                                  id="cars"
                                  className="form-control form-control-alternative"
                                  defaultValue={user?.Nationality}
                                  style={{ height: "46px" }}
                                  {...register("Nationality")}
                                >
                                  <option value="">-- {t("select")} --</option>
                                  <option
                                    value="Indian"
                                    selected={
                                      user?.Nationality === "Indian"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Indian")}
                                  </option>
                                  <option
                                    value="Pakistani"
                                    selected={
                                      user?.Nationality === "Pakistani"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Pakistani")}
                                  </option>
                                  <option
                                    value="Emirati"
                                    selected={
                                      user?.Nationality === "Emirati"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Emirati")}
                                  </option>
                                  <option
                                    value="Saudi"
                                    selected={
                                      user?.Nationality === "Saudi"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Saudi")}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="row plans"
                                style={{ padding: "0px", marginTop: "20px" }}
                              >
                                <div className="col-lg-6">
                                  <label
                                    className="plan one basic-plan"
                                    htmlFor="basic"
                                    style={{ padding: "0px" }}
                                  >
                                    <input
                                      type="radio"
                                      id="basic"
                                      value="1"
                                      defaultChecked={
                                        user?.GenderId == 1 ? true : false
                                      }
                                      {...register("GenderID")}
                                    />
                                    <div
                                      className="plan-content"
                                      style={{ padding: "4px" }}
                                    >
                                      <i
                                        className="mdi mdi-gender-male"
                                        style={{
                                          fontSize: "25px",
                                          marginRight: "2px",
                                          color: "#788bc4",
                                        }}
                                      ></i>
                                      <div className="plan-details1">
                                        <span
                                          style={{
                                            color: "#788bc4",
                                            lineHeight: "1",
                                          }}
                                        >
                                          {t("Male")}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                                <div className="col-lg-6">
                                  <label
                                    className="plan one complete-plan"
                                    htmlFor="complete"
                                    style={{ padding: "0px" }}
                                  >
                                    <input
                                      type="radio"
                                      id="complete"
                                      value="2"
                                      defaultChecked={
                                        user?.GenderId == 2 ? true : false
                                      }
                                      {...register("GenderID")}
                                    />
                                    <div
                                      className="plan-content"
                                      style={{ padding: "4px" }}
                                    >
                                      <i
                                        className="mdi mdi-gender-female"
                                        style={{
                                          fontSize: "25px",
                                          marginRight: "2px",
                                          color: "#788bc4",
                                        }}
                                      ></i>
                                      <div className="plan-details1">
                                        <span
                                          style={{
                                            color: "#788bc4",
                                            lineHeight: "1",
                                          }}
                                        >
                                          {t("Female")}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              <div className="plans"></div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  htmlFor="cars"
                                  className="form-control-label"
                                >
                                  {t("MaritalStatus")}{" "}
                                </label>
                                <select
                                  id="cars1"
                                  className="form-control form-control-alternative"
                                  style={{ height: "46px" }}
                                  {...register("MaritalStatus")}
                                >
                                  <option
                                    value=""
                                    selected={
                                      user?.MaritalStatus === null
                                        ? true
                                        : false
                                    }
                                  >
                                    -- {t("select")} --
                                  </option>
                                  <option
                                    value="Married"
                                    selected={
                                      user?.MaritalStatus === "Married"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Married")}
                                  </option>
                                  <option
                                    value="Widowed"
                                    selected={
                                      user?.MaritalStatus === "Widowed"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Widowed")}
                                  </option>
                                  <option
                                    value="Separated"
                                    selected={
                                      user?.MaritalStatus === "Separated"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Separated")}{" "}
                                  </option>
                                  <option
                                    value="Divorced"
                                    selected={
                                      user?.MaritalStatus === "Divorced"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Divorced")}{" "}
                                  </option>
                                  <option
                                    value="Single"
                                    selected={
                                      user?.MaritalStatus === "Single"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Single")}{" "}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  htmlFor="cars"
                                  className="form-control-label"
                                >
                                  {t("Disability")}
                                </label>
                                <select
                                  id="cars11"
                                  className="form-control form-control-alternative"
                                  style={{ height: "46px" }}
                                  {...register("SpecialNeeds")}
                                >
                                  <option
                                    value=""
                                    selected={
                                      user?.SpecialNeeds === null ? true : false
                                    }
                                  >
                                    -- {t("select")} --
                                  </option>

                                  <option
                                    value="Vision Impairment"
                                    selected={
                                      user?.SpecialNeeds === "Vision Impairment"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("VisionImpairment")}
                                  </option>
                                  <option
                                    value="Hard of hearing"
                                    selected={
                                      user?.SpecialNeeds === "Hard of hearing"
                                        ? true
                                        : false
                                    }
                                  >
                                    {" "}
                                    {t("Hardofhearing")}
                                  </option>
                                  <option
                                    value="Mental health conditions"
                                    selected={
                                      user?.SpecialNeeds ===
                                        "Mental health conditions"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Mentalhealthconditions")}
                                  </option>
                                  <option
                                    value="None"
                                    selected={
                                      user?.SpecialNeeds === "None"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("None")}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6 className="heading-small text-muted mb-4">
                          {t("ProfessionalDetails")}
                        </h6>
                        <div className="pl-lg-0">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {t("Education")}
                                </label>
                                <input
                                  type="text"
                                  id="input-username"
                                  className="form-control form-control-alternative"
                                  defaultValue={
                                    !user?.Education ? "" : user?.Education
                                  }
                                  {...register("Education")}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  {t("Major")}
                                </label>
                                <input
                                  type="text"
                                  id="input-email"
                                  className="form-control form-control-alternative"
                                  defaultValue={!user?.Major ? "" : user?.Major}
                                  {...register("Major")}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  {t("Occupation")}
                                </label>
                                <input
                                  type="text"
                                  id="input-email"
                                  className="form-control form-control-alternative"
                                  defaultValue={
                                    user?.Occupation === null
                                      ? ""
                                      : user?.Occupation
                                  }
                                  {...register("Occupation")}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  htmlFor="cars"
                                  className="form-control-label"
                                >
                                  {t("AreasofInterest")}
                                </label>
                                <select
                                  id="cars11"
                                  className="form-control form-control-alternative"
                                  style={{ height: "46px" }}
                                  {...register("AreasOfInterest")}
                                >
                                  <option
                                    value=""
                                    selected={
                                      user?.AreasofInterest === null
                                        ? true
                                        : false
                                    }
                                  >
                                    -- {t("select")} --
                                  </option>

                                  {/* <option
                                    value="Cultural"
                                    selected={
                                      user?.AreasofInterest === "Cultural"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Cultural")}
                                  </option>
                                  <option
                                    value="Technical"
                                    selected={
                                      user?.AreasofInterest === "Technical"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Technical")}
                                  </option>
                                  <option
                                    value="Sports"
                                    selected={
                                      user?.AreasofInterest === "Sports"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Sports")}
                                  </option>
                                  <option
                                    value="Social"
                                    selected={
                                      user?.AreasofInterest === "Social"
                                        ? true
                                        : false
                                    }
                                  >
                                    {t("Social")}
                                  </option> */}

                                  {interestList.map((option) => (
                            <option key={option?.InterestId} value={option?.InterestId}
                            selected={
                              user?.AreasofInterest == option?.InterestId
                                ? true
                                : false
                            }
                            >
                              {i18n.language === "en" ? option.NameEng : option.NameAra}
                            </option>
                          ))}



                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6 className="heading-small text-muted mb-4">
                          {t("ContactDetails")}
                        </h6>
                        <div className="pl-lg-0">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group focused">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-username"
                                >
                                  {t("Email")}
                                </label>
                                <input
                                  type="email"
                                  id="input-username"
                                  className="form-control form-control-alternative"
                                  defaultValue={!user?.Email ? "" : user?.Email}
                                  {...register("Email", {
                                    required: {
                                      value: true,
                                      message: t("EmailRequired"),
                                    },
                                    pattern: {
                                      value:
                                        /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-z0-9-]+\.)+[a-z]{2,6}$/,
                                      message: t("EmailInvalid"),
                                    },
                                  })}
                                />
                                {errors.Email &&
                                  errors.Email.type == "required" && (
                                    <p
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {t("EmailRequired")}
                                    </p>
                                  )}
                                {errors.Email &&
                                  errors.Email.type == "pattern" && (
                                    <p
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {t("EmailInvalid")}
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label style={{ width: "100%" }}>
                                {" "}
                                {t("EmergencyContacts")}{" "}
                                <font color="red"> *</font>
                                <font
                                  color="red"
                                  style={{ color: "#88a5c8c4" }}
                                >
                                  {" "}
                                  ({t("AtleastOneEmergency")})
                                </font>{" "}
                                <span
                                  style={{
                                    float: "right",
                                    fontSize: "25px",
                                    color: "#012690",
                                  }}
                                >
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      append({});
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-plus-circle"
                                      style={{
                                        fontSize: "30px",
                                        color: "#012690",
                                      }}
                                    ></i>
                                  </button>
                                </span>
                              </label>
                            </div>
                            {fields.map((obj, index) => {
                              return (
                                <div className="col-md-12" key={obj.id}>
                                  <div className="row">
                                    <div className="col-lg-4">
                                      <div className="form-group focused">
                                        <input
                                          type="text"
                                          id="input-city"
                                          className="form-control form-control-alternative"
                                          placeholder={t("Name")}
                                          {...register(
                                            `EmergencyContacts[${index}].name`,
                                            {
                                              required: {
                                                value: true,
                                                message: t("NameRequired"),
                                              },
                                            }
                                          )}
                                          defaultValue={obj.name}
                                        />
                                        {errors?.EmergencyContacts?.[index]
                                          ?.name &&
                                          errors?.EmergencyContacts?.[index]
                                            ?.name?.type == "required" && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {t("NameRequired")}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group focused">
                                        <input
                                          type="text"
                                          id="input-country"
                                          className="form-control form-control-alternative"
                                          placeholder={t("Mobile")}
                                          {...register(
                                            `EmergencyContacts[${index}].mobile`,
                                            {
                                              required: {
                                                value: true,
                                                message: t("MobileRequired"),
                                              },
                                              pattern: {
                                                value:
                                                  /^(\+9665|9665|05|5)[0-9]{8}$/,
                                                message: t("MobileInvalid"),
                                              },
                                            }
                                          )}
                                          defaultValue={obj.mobile}
                                        />
                                        {errors?.EmergencyContacts?.[index]
                                          ?.mobile &&
                                          errors?.EmergencyContacts?.[index]
                                            ?.mobile.type == "required" && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {t("MobileRequired")}
                                            </p>
                                          )}
                                        {errors?.EmergencyContacts?.[index]
                                          ?.mobile &&
                                          errors?.EmergencyContacts?.[index]
                                            ?.mobile.type == "pattern" && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {t("MobileInvalid")}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          id="input-postal-code"
                                          className="form-control form-control-alternative"
                                          placeholder={t("Email")}
                                          {...register(
                                            `EmergencyContacts[${index}].email`,
                                            {
                                              required: {
                                                value: true,
                                                message: t("EmailRequired"),
                                              },
                                              pattern: {
                                                value:
                                                  /[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-z0-9-]+\.)+[a-z]{2,6}$/,
                                                message: t("EmailInvalid"),
                                              },
                                            }
                                          )}
                                          defaultValue={obj.email}
                                        />
                                        {errors?.EmergencyContacts?.[index]
                                          ?.email &&
                                          errors?.EmergencyContacts?.[index]
                                            ?.email.type == "required" && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {t("EmailRequired")}
                                            </p>
                                          )}
                                        {errors?.EmergencyContacts?.[index]
                                          ?.email &&
                                          errors?.EmergencyContacts?.[index]
                                            ?.email.type == "pattern" && (
                                            <p
                                              style={{
                                                color: "red",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {t("EmailInvalid")}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  {fields.length > 1 && (
                                    <span
                                      style={{
                                        float: "right",
                                        fontSize: "25px",
                                        color: "#ff00009e",
                                        position: "absolute",
                                        right: "-20px",
                                        top: "0",
                                      }}
                                    >
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          remove(index);
                                        }}
                                      >
                                        <i
                                          className="mdi mdi-minus-circle"
                                          style={{
                                            fontSize: "30px",
                                            color: "rgb(211 40 5)",
                                          }}
                                        ></i>
                                      </button>
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </form>
                      <div className="pl-lg-0">
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{ textAlign: "center" }}
                          >
                            <button
                              // type="submit"
                              className="btn btn-outline-primary btn-fw"
                              onClick={handleSubmit(onSubmit)}
                            >
                              {t("Update")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openPopup && (
            <MobilePopup  open={openPopup} onCloseModal={handleCloseModal} />
          )}
          {openRenewalPopup && (
            <RenewalPopup open={openRenewalPopup} onCloseModal={handleCloseModal} />
          )}
          {openHistoryPopup && (
            <HistoryPopup open={openHistoryPopup} onCloseModal={handleCloseModal} />
          )}
          {openSubscribePopup && (
            <SubscribePopup open={openSubscribePopup} onCloseModal={handleCloseModal} />
          )}
          {openFreezePopup && (
            <FreezeRequestPopup open={openFreezePopup} onCloseModal={handleCloseModal} />
          )}
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Profile;
