import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import img from "../../images/no-data.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { API } from "../../Constants/Global";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
      // style={{
      //   direction: i18n.language === "en" ? "ltr" : "rtl",
      // }}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
          {/* <Icon>
            <CloseIcon />
          </Icon> */}
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const EventsPopup = (props) => {
  const Event = props.event;
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");

  const ACTIONS = {
    next: "next",
    previous: "previous",
  };
  const lng = localStorage.getItem("i18nextLng");

  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(formReducer, { step: 0 });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const handleClose = () => {
    props.onCloseModal(false);
  };
  const { t, i18n } = useTranslation();

  const setEventStatus = (statusId) => {
    const eventId = Event?.EventId;
    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
    const subData = {
      EventId: eventId,
      EventStatusId: statusId,
      SubscriberId: subscriberId,
    };

    const lng = localStorage.getItem("i18nextLng");
    const tkn = localStorage.getItem("token");
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": lng === "ar" ? "Ar" : "En",
      },
    };
    axios
      .post(
        `${API}events/setsubscribereventstatus`,
        subData,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          toast.success(response.data.Message);
          window.location.reload();
        } else {
          toast.error(response.data.Message);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
      }}
    >
      <BootstrapDialog
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
        fullWidth={true}
        maxWidth={"lg"}
      >
        {/* {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )} */}

        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {/* <div>
           
          </div> */}
        </BootstrapDialogTitle>
        <DialogContent>
          <Helmet>
            <script
              async="true"
              src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"
            ></script>
            <script async="true" src="/script/popupFormJQuery.js"></script>
          </Helmet>

          <div className="wrapper">
            <div className="product-single">
              <div className="product-gallery">
                <div id="slider-wrap">
                  <ul className="slides">
                    <Carousel thumbWidth={0} showThumbs={false}>
                      {Event.Images.map((ob, ind) => {
                        return (
                          <div>
                            <img src={ob} alt="Event Image" />
                          </div>
                        );
                      })}
                    </Carousel>
                  </ul>
                </div>
              </div>
              <div className="product-details">
                <h1 className="product-title">
                  {i18n.language === "en"
                    ? Event.EventNameEng
                    : Event.EventNameAra}
                </h1>

                <div className="product-description">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s,
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <ul style={{ paddingLeft: "0" }}>
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <span style={{ fontSize: "20px", color: "#012690" }}>
                            <i className="mdi mdi-map-marker-circle"></i>
                          </span>{" "}
                          {i18n.language === "en"
                            ? Event.LocationEng
                            : Event.LocationAra}
                        </p>{" "}
                      </li>
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <span style={{ fontSize: "20px", color: "#012690" }}>
                            <i className="mdi mdi-account-check"></i>
                          </span>{" "}
                          <span>{Event.Organizer}</span>{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul style={{ paddingLeft: "0" }}>
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <span style={{ fontSize: "20px", color: "#012690" }}>
                            <i className="mdi mdi mdi-timer"></i>
                          </span>{" "}
                          {Event.Slot}
                        </p>{" "}
                      </li>
                      <li>
                        {" "}
                        <p>
                          {" "}
                          <span style={{ fontSize: "20px", color: "#012690" }}>
                            <i className="mdi mdi mdi-timetable"></i>
                          </span>{" "}
                          {Event.EventDates.split(",").map((ob, ind) => {
                            return <span>{ob} </span>;
                          })}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">Event Type - {Event.EventType}</div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="btn-group" role="group" style={{ padding: "10px" }}>
            <button
              type="button"
              onClick={() => setEventStatus(1)}
              className="btn btn-outline-primary"
            >
              {t("Yes")}
            </button>
            <button
              type="button"
              onClick={() => setEventStatus(2)}
              className="btn btn-outline-primary"
            >
              {t("No")}
            </button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EventsPopup;
