
import React, { useEffect, useState, useReducer, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CloseIcon from "@mui/icons-material/Close";
import "./custom.scss";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import maleImg from "../../images/male.jpg";
import femaleImg from "../../images/female.jpg";
import { makeStyles } from "@mui/styles";
import authContext from "../../auth-context";
import { API } from "../../Constants/Global";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "25px",
  },
  "& .MuiDialogActions-root": {
    padding: "10px",
  },
  "& .MuiInputLabel-root": {
    color: "#43425d",
    fontWeight: "bold",
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    width: "360px",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const { t, i18n } = useTranslation();
  return (
    <DialogTitle
      className={i18n.language === "en" ? "ltr" : "rtl"}
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <CloseIcon
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: i18n.language === "en" ? 8 : "auto",
            left: i18n.language === "en" ? "auto" : 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: "pointer",
          }}
        >
        </CloseIcon>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const defaultValues = {
  account_name: "",
};
const RenewalPopup = (props) => {
  const [subscriberTypes, setSubscriberTypes] = useState(null);
  const [selectedSubscriberType, setSelectedSubscriberType] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [id, setId] = useState(null);
  const [error, setError] = useState(false);
  const { t, i18n } = useTranslation();
  const lng = localStorage.getItem("i18nextLng");
  const tkn = localStorage.getItem("token");
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const [openAlert, setOpenAlert] = useState(false);
  const [icon, setIcon] = useState("mail_outline");
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [isLoading, setIsLoading] = useState(true);
  const { token, userLogin, isLoggedIn } = useContext(authContext);

  const [openStartDate, setOpenStartDate] = useState(false);
  const [startDate, setStartDate] = useState("today");
  var date = new Date();
  const [todayDate, setTodayDate] = useState(date);
  var tomDate = new Date();
  tomDate.setDate(date.getDate() + 1);
  const [tomorrowDate, setTomorrowDate] = useState(tomDate);
  const ACTIONS = {
    next: "next",
    previous: "previous",
  };
  const formReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.next:
        return { step: state.step + 1 };
      case ACTIONS.previous:
        return { step: state.step - 1 };
      default:
        return state;
    }
  };
  function convert(str) {
    const date = new Date(str);
    const mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const [renewalMinDate, setRenewalMinDate] = useState(null);
  const useStyles = makeStyles({
    root: {
      direction: i18n.language == "en" ? "ltr" : "rtl",
      backgroundColor: "red",
    },
  });
  const classes = useStyles();
  const [state, dispatch] = useReducer(formReducer, { step: 0 });
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const subscriberTypeID = watch("MembershiptypeId", "");
  const Start_Date = watch("StartDate", null);
  const StartDate = watch("startDateValue", 'today');
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => {
    props.onCloseModal(false);
  };
  function handleCloseModal(event, data) {
    setOpenAlert(false);
  }
  const navigate = useNavigate();

  const getsubscribertypes = () => {
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    axios
      .get(
        `${API}public/getsubscribertypes?GenderId=${user?.GenderId}&Dob=${user?.DOB}`
      )
      .then((response) => {
        setSubscriberTypes(response.data.Data);
        setRenewalMinDate(new Date(user?.EndDate));
        setIsLoading(false);
      })
      .catch((error) => setIsLoading(false));
  };

  useEffect(() => {
    if (
      subscriberTypeID != "" &&
      subscriberTypeID
    ) {
      setIsLoading(true);
      let config = {
        headers: {
          "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
        },
      };

      let _startdate = '';
      if(StartDate == 'today'){
        _startdate = convert(todayDate);
      }
      else{
        _startdate = convert(tomorrowDate);
      }

      axios
        .get(
          `${API}public/setenddateforselectedsubscribertype?SubscriberTypeId=${subscriberTypeID}&StartDate=${_startdate}`,
          config
        )
        .then((response) => {
          if (response.data.isSuccess) {
            setEndDate(response.data.Data);
            setIsLoading(false);

          } else {
            toast.error(response.data.Message);
            setIsLoading(false);

          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error);
        });
    }
  }, [subscriberTypeID, StartDate]);

  useEffect(() => {
    getsubscribertypes();
  }, []);

  const submitRenewal = () => {
   setIsLoading(true);

   let _startdate = '';
   if(StartDate == 'today'){
     _startdate = convert(todayDate);
   }
   else{
     _startdate = convert(tomorrowDate);
   }

    const subscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
    let config = {
      headers: {
        APICODE: tkn,
        "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
      },
    };
    const subData = {
      SubscriberId: subscriberId,
      SubscriberTypeID: subscriberTypeID,
      StartDate:_startdate
    };

    axios
      .post(
        `${API}subscriber/subscriberRenewalPay`,
        subData,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
           if (response.data.PaymentGatewayInfo != null) {
            if (response.data.PaymentGatewayInfo.result.code === "000.200.100") {
              window.location = response.data.PaymentGatewayUrl;
            }
          }
           setIsLoading(false);
           handleClose();
        } else {
          toast.error(response.data.Message);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error);
      });
  };

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        Width: "800px",
      }}
    >
      <BootstrapDialog className="modelheight"
        open={props.open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        style={{
          direction: i18n.language === "en" ? "ltr" : "rtl",
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        {isLoading && (
          <div className="loader-popup">
            <div className="circle-loader" style={{ top: "50%" }}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerclassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#363636",
              color: "#fff",
              padding: "25px",
            },
            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "white",
              },
            },
            error: {
              duration: 5000,
              theme: {
                primary: "red",
                secondary: "black",
              },
            },
          }}
        />
        <BootstrapDialogTitle
          style={{ borderBottom: "1px solid #ccc" }}
          id="renewal-popup"
          onClose={handleClose}
        >
          <div>
            <h3>{t("Renewal")}</h3>
          </div>
        </BootstrapDialogTitle>
        <DialogContent>
          {state.step === 0 && (
            <div id="" style={{ width: "100%" }}>
              <div className={i18n.language === "en" ? "ltr" : "rtl"}>
                <div className="three">
                  <h1>{t("SubscriberTypes")}</h1>
                </div>
              </div>
              <form className="login100-form validate-form">

                <div className="main-container" style={{ width: "100%" }}>
                  <div
                    className={
                      i18n.language === "en"
                        ? "membership"
                        : "membershipar"
                    }
                  >
                    {subscriberTypes?.map((list, index) => {
                      return (
                        <div className="reg" key={list.MembershipTypeId} >
                          <input
                            type="radio"
                            id={`controlReg_${index}`}
                            value={list.MembershipTypeId}
                            onClick={() =>   setSelectedSubscriberType(list)}
                            {...register("MembershiptypeId", {
                              required: {
                                value: true,
                                message: t("Membershiptype"),
                              },
                            })}
                          />
                          <label htmlFor={`controlReg_${index}`}>
                            <div className="card card-1">
                              <div className="card__icon">
                                <div className="row">
                                  <div
                                    className={
                                      i18n.language === "en"
                                        ? "col-lg-4 borderRight"
                                        : "col-lg-4 borderLeft"
                                    }
                                  >
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      SR. {list.Fees}
                                    </span>
                                  </div>
                                  <div className="col-lg-6">
                                    <p>
                                      <b>
                                        {i18n.language === "en" &&
                                          list.NameEng}
                                        {i18n.language === "ar" &&
                                          list.NameAra}
                                      </b>
                                    </p>
                                    <p style={{ fontSize: "13px" }}>
                                      <b>{t("Duration")} </b> :{" "}
                                      <span>
                                        {i18n.language === "en" &&
                                          list?.ValidityNameEng}
                                        {i18n.language === "ar" &&
                                          list?.ValidityNameAra}
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="col-lg-2"
                                    style={{
                                      textAlign: "right",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <a href="#">
                                      {" "}
                                      <p className="card__exit">
                                        {i18n.language === "en" && (
                                          <FontAwesomeIcon
                                            icon={faArrowRight}
                                          />
                                        )}
                                        {i18n.language === "ar" && (
                                          <FontAwesomeIcon
                                            icon={faArrowLeft}
                                          />
                                        )}
                                      </p>{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors.MembershiptypeId &&
                    errors.MembershiptypeId.type == "required" && (
                      <p style={{ color: "red", marginTop: "4%" }}>
                        {t("Membershiptype")}
                      </p>
                    )}

                  {/* <div
                    className={`${lngClass} wrap-input100 validate-input m-b-16`}
                  >
                    <Controller
                      name="StartDate"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: {
                          value: true,
                          message: t("StartDateRequired"),
                        },
                      }}
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                        >
                          <label style={{ padingTop: "5px" }}>
                            Start Date
                          </label>
                          <DesktopDatePicker
                           open={openStartDate}
                           onOpen={() => setOpenStartDate(true)}
                           onClose={() => setOpenStartDate(false)}
                            views={["year", "month", "day"]}
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            className={classes.root}
                            
                            minDate={renewalMinDate}
                            renderInput={(params) => (
                              <TextField {...params} onClick={(e) => setOpenStartDate(true)}/>
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    {errors.StartDate &&
                      errors.StartDate.type == "required" && (
                        <p style={{ color: "red" }}>
                          {t("StartDateRequired")}
                        </p>
                      )}
                  </div>
                  {endDate && <p  style={{marginBottom: "0"}}>End date is: {endDate}</p>} */}

<div className="col-lg-12">
                    <div
                      className="row plans"
                      style={{ padding: "0px", marginTop: "20px" }}
                    >
                      <div className="col-lg-12" >
                      <label className={i18n.language === "en" ? "ltr" : "rtl"} style={{width:"100%"}}> {t("StartDate")}</label>
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="plan one basic-plan"
                          htmlFor="today"
                          style={{ padding: "0px", width: "100%" }}
                        >
                          <input
                            type="radio"
                            name="startDateValue"
                            id="today"
                            value="today"
                            checked={
                              StartDate == 'today' ? true : false
                            }
                            {...register("startDateValue")}
                          />
                          <div
                            className="plan-content"
                            style={{ padding: "4px", paddingBottom:"14px" }}
                          >
                            <div className="plan-details1">
                              <span
                                style={{
                                  color: "#788bc4",
                                  lineHeight: "1",
                                }}
                              >
                                {t("Today")} ({todayDate.toDateString()})
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="plan one complete-plan"
                          htmlFor="tomorrow"
                          style={{ padding: "0px", width: "100%" }}
                        >
                          <input
                            type="radio"
                            name="startDateValue"
                            id="tomorrow"
                            value="tomorrow"
                            checked={
                              StartDate == 'tomorrow' ? true : false
                            }
                            {...register("startDateValue")}
                          />
                          <div
                            className="plan-content"
                            style={{ padding: "4px",paddingBottom:"14px" }}
                          >

                            <div className="plan-details1">
                              <span
                                style={{
                                  color: "#788bc4",
                                  lineHeight: "1",
                                }}
                              >
                                {t("Tomorrow")} ({tomorrowDate.toDateString()})
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: "20px" }} >
                      {endDate && <p>End date is: {endDate}</p>}
                      </div>
                    </div>
                  </div>

                </div>
              </form>
              <div className={i18n.language === "en" ? "ltr" : "rtl"}>
                {/* <p><b>Note <span style={{color:"red"}}>*</span> : </b></p> */}
                <p style={{marginBottom: "0", fontSize: "0.9rem",    textAlignLast: "start"}}>{t("ConditionsForEnteringthePool")} :</p>
                <p style={{marginBottom: "0", fontSize: "0.9rem",  textAlignLast: "start"}}>{t("swimmingPoolTests")}</p>
                <p style={{marginBottom: "0", fontSize: "0.9rem",  textAlignLast: "start"}}>{t("TestsAreFollows")} :</p>
                <div className="row">
                <div className="col-md-6">

                <ul style={{paddingRight: "10px"}}> 
                <li style={{listStyleType:"disc",  textAlignLast: "start"}}>{t("HIVTest")}</li>
                  <li style={{listStyleType:"disc",  textAlignLast: "start"}}>{t("Syphilis")}</li>
                  <li style={{listStyleType:"disc",  textAlignLast: "start"}}>{t("LiverScreening")}</li>
                  
                </ul>
                </div>
                <div className="col-md-6">

                <ul>
                  
                  <li style={{listStyleType:"disc", textAlignLast: "start"}}>{t("Urinalysis")}</li>
                  <li style={{listStyleType:"disc", textAlignLast: "start"}}>{t("BloodImage")}</li>
                </ul>
                </div>
                </div>
               
              </div>
            </div>
            
            )}
          {state.step === 1 && (
            <section className={i18n.language === "en" ? "ltr" : "rtl"}>
              <div className="receipt">
                <header className="receipt__header">
                  <p
                    className="receipt__title"
                    style={{ paddingTop: "10px", fontSize: "1.2rem" }}
                  >
                    {t("SubscriptionSummary")}
                  </p>
                </header>
                <dl className="receipt__list">
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("Type")} </dt>
                    <dd className="receipt__cost">
                    {i18n.language === "en"
                                      ? selectedSubscriberType?.NameEng
                                      : selectedSubscriberType?.NameAra}
                    
                    </dd>
                  </div>
                  <div className="receipt__list-row">
                                                     
                    <dt className="receipt__item">{t("Duration")}</dt>
                    <dd className="receipt__cost">
                    <span>
                                        {i18n.language === "en" &&
                                        selectedSubscriberType?.ValidityNameEng}
                                        {i18n.language === "ar" &&
                                        selectedSubscriberType?.ValidityNameAra}
                                      </span>
                    </dd>
                  </div>
                  <div className="receipt__list-row">
                    <dt className="receipt__item"> {t("ValidFrom")}</dt>
                    <dd className="receipt__cost">
                    {StartDate === "today" &&
                          convert(todayDate)}
                        {StartDate === "tomorrow" &&
                          convert(tomorrowDate)}
                    </dd>
                  </div>
                  <div className="receipt__list-row">
                    <dt className="receipt__item">{t("ValidTo")}</dt>
                    <dd className="receipt__cost">
                    {endDate}
                    </dd>
                  </div>
                  <div className="receipt__list-row receipt__list-row--total">
                    <dt className="receipt__item">{t("grandTotal")}</dt>
                    <dd className="receipt__cost">SAR. {selectedSubscriberType?.Fees}</dd>
                  </div>
                </dl>
                <div className={i18n.language === "en" ? "ltr" : "rtl"} >
                {/* <p><b>Note <span style={{color:"red"}}>*</span> : </b></p> */}
                <p style={{marginBottom: "0", fontSize: "0.9rem",    textAlignLast: "start"}}>{t("ConditionsForEnteringthePool")} :</p>
                <p style={{marginBottom: "0", fontSize: "0.9rem",  textAlignLast: "start"}}>{t("swimmingPoolTests")}</p>
                <p style={{marginBottom: "0", fontSize: "0.9rem",  textAlignLast: "start"}}>{t("TestsAreFollows")} :</p>
                <div className="row">
                <div className="col-md-6">

                <ul style={{paddingRight: "10px"}}> 
                <li style={{listStyleType:"disc",  textAlignLast: "start"}}>{t("HIVTest")}</li>
                  <li style={{listStyleType:"disc",  textAlignLast: "start"}}>{t("Syphilis")}</li>
                  <li style={{listStyleType:"disc",  textAlignLast: "start"}}>{t("LiverScreening")}</li>
                  
                </ul>
                </div>
                <div className="col-md-6">

                <ul>
                  
                  <li style={{listStyleType:"disc", textAlignLast: "start"}}>{t("Urinalysis")}</li>
                  <li style={{listStyleType:"disc", textAlignLast: "start"}}>{t("BloodImage")}</li>
                </ul>
                </div>
                </div>
               
              </div>
              </div>
            </section>
          )}
        </DialogContent>
        <DialogActions>
          <div>
            {state.step == 0 && (
              <button
                disabled={subscriberTypeID ? false : true}
                className="button"
                onClick={() => dispatch({ type: ACTIONS.next })}
              >
                {t("Next")}
                {i18n.language === "en" ? (
                  <i
                    className="mdi mdi mdi-chevron-right"
                    style={{ float: "end" }}
                  ></i>
                ) : (
                  <i
                    className="mdi mdi mdi-chevron-left"
                    style={{ float: "end" }}
                  ></i>
                )}
              </button>
            )}
            {state.step == 1 && (
              <>
                <button
                  className="buttonPre"
                  onClick={() => dispatch({ type: ACTIONS.previous })}
                >
                  {i18n.language === "en" ? (
                    <i
                      className="mdi mdi mdi-chevron-left"
                      style={{ float: "end" }}
                    ></i>
                  ) : (
                    <i
                      className="mdi mdi mdi-chevron-right"
                      style={{ float: "end" }}
                    ></i>
                  )}
                  {t("Previous")}
                </button>
                <button className="button"  onClick={submitRenewal}>
                  {t("submit")}
                </button>
              </>
            )}
          </div>
        </DialogActions>
      </BootstrapDialog>
      
    </div>
  );
};

export default RenewalPopup;
