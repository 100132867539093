import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import authContext from "../../auth-context";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import AppLayout from "./AppLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import img from "../../images/noimg.png";
import { useTranslation } from "react-i18next";
import SportsPopup from "./SportsPopup";
import { API } from "../../Constants/Global";
import SubscribePopup from "./SubscribePopup";
import noDataImg from "../../images/no-data.png";
import waterSports from "../../images/Water-Sports.png";
import spaicon from "../../images/Spa.png";
import advanceFitness from "../../images/Advance-Fitness.png";
import beginnerFitness from "../../images/Beginner-Fitness.png";
import intermediateFitness from "../../images/Intermediate-Fitness.png";

const ScheduleSports = () => {
  const navigate = useNavigate();
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { Exercise } = useParams();
  const [ExerciseType, setExerciseType] = useState(null);
  const [services, setServices] = useState(null);
  const { t, i18n } = useTranslation();
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState(null);
  //const [exercisetypeList, setExercisetypeList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [openSubscribePopup, setOpenSubscribePopup] = useState(false);
  const gendrID = user?.GenderId;
  const subscriberId = user?.SubscriberId;
  const [openFilter, setOpenFilter] = useState(false);
 
  const [SelectedDays, setSelectedDays] = useState(null);
  const [Price, setPrice] = useState(null);
  const [isApplyExerciseType, setIsApplyExerciseType] = useState(true);

  function handleCloseModal(event, data) {
    setOpenPopup(false);
    setOpenSubscribePopup(false);
  }

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const serviceId = watch("ServiceID");
  const lng = localStorage.getItem("i18nextLng");

  let config = {
    headers: {
      APICODE: token,
      "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
    },
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${API}public/getexcercisetypes`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setExerciseType(
            Exercise && response?.data?.Data?.find((obj) => obj?.NameEng?.replaceAll(' ', '_')?.toLowerCase() == Exercise)
          );
          //setExercisetypeList(response.data.Data);
          
        } else {
          //setExercisetypeList([]);
          setIsLoading(false);
        }
      })
      .catch((error) =>{
        setIsLoading(false);
        console.log(error);
      } );
  }, []);

  const sort = (value) => {
    const sorted = services?.slice().sort((a, b) => {
      if (value === "1") {
        return a.Price > b.Price ? 1 : -1;
      }
      if (value === "2") {
        return a.Price < b.Price ? 1 : -1;
      }
    });
    setServices(sorted);
  };




  function serviceRadio(servicId, price) {
    const serviceId = servicId;
    if (price > 0) {
      serviceId && setOpenPopup(true);
      setService(
        serviceId && services?.find((obj) => obj?.ServiceId == serviceId)
      );
    }
    else {
      let _redirectUrl = {
        "serviceId": serviceId,
        "page": "sports/booking"
      }
      localStorage.setItem("redirectUrl", JSON.stringify(_redirectUrl));
      setOpenSubscribePopup(true);
    }
  }

  function handleReset() {
    setPrice(null);
    setSelectedDays(null);
    document.getElementById('exercisetype').value = '';
    setIsLoading(true);
    axios
      .get(
        `${API}service/getservicetypesbyservicetype?ServiceType=Sports&GenderId=${gendrID}&SubscriberId=${subscriberId}&ExerciseType=${ExerciseType?.Lookup_ExerciseTypeId}&SelectedDays=null&Price=null`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setServices(response.data.Data);
          setIsLoading(false);
        } else {
          setServices([]);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }

  function applyFilterExerciseType() {
    setIsLoading(true);
    axios
      .get(
        `${API}service/getservicetypesbyservicetype?ServiceType=Sports&GenderId=${gendrID}&SubscriberId=${subscriberId}&ExerciseType=${ExerciseType?.Lookup_ExerciseTypeId}&SelectedDays=&Price=`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setIsApplyExerciseType(true);
          setServices(response.data.Data);
          setIsLoading(false);
        } else {
          setServices([]);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }


  function applyFilter() {
    setIsLoading(true);
    axios
      .get(
        `${API}service/getservicetypesbyservicetype?ServiceType=Sports&GenderId=${gendrID}&SubscriberId=${subscriberId}&ExerciseType=${ExerciseType?.Lookup_ExerciseTypeId}&SelectedDays=${SelectedDays}&Price=${Price}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setServices(response.data.Data);
          setIsLoading(false);
        } else {
          setServices([]);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }

  function handleFilterPopup(i) {
    setOpenFilter(i);
  }

  useEffect(() => {
    if(ExerciseType)
      applyFilterExerciseType();
  }, [ExerciseType]);
  return isLoading ? (
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-heart-pulse"></i>
              </span>{" "}
              {t("sportServices")}  <i className={`mdi mdi-chevron-${i18n.language === "ar" ? "left" : "right"}`}></i>
              {" "}
              {i18n.language === "ar" ? ExerciseType?.NameAra : ExerciseType?.NameEng}
            </h3>
          </div>
          <h4>{t("selectService")}</h4>
          {/* {!isApplyExerciseType && <div>
            <div className="grid-wrapper grid-col-auto text-center" style={{ justifyContent: "center" }}>
              {exercisetypeList && exercisetypeList.length > 0 ? (
                exercisetypeList?.map((obj, index) => {
                  return (
                    <>
                      <label for={'radio-card-' + index} className="radio-card col-">
                        <input type="radio" name="radio-card" id={'radio-card-' + index} onChange={() => setExerciseType(obj?.Lookup_ExerciseTypeId)} />
                        <div className="card-content-wrapper">
                          <span className="check-icon"></span>
                          <div className="card-content">
                            <img
                              src={obj?.Icon}
                              alt="" style={{ width: "100%" }}
                            />
                            <h4> {i18n.language === "en" ? obj.NameEng : obj.NameAra}</h4>

                          </div>
                        </div>
                      </label>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </div>

            <div className="pl-lg-0">
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ textAlign: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-fw"
                    onClick={() => applyFilterExerciseType()}
                  >
                    {t("Continue")}
                  </button>
                </div>
              </div>
            </div>

          </div>} */}


          <div>
            <form>
              <div className="row" style={{ justifyContent: "end" }}>
                <div>
                  <select
                    onChange={(e) => sort(e.target.value)}
                    className="form-control form-control-alternative"
                    style={{
                      width: "fit-content",
                      height: "40px",

                    }}
                  >
                    <option>Sort</option>
                    <option value="1">Price (Low to High)</option>
                    <option value="2">Price (High to Low)</option>
                  </select>
                </div>
                <div className={`${lngClass} nav-item dropdown`}>
                  <a
                    className="nav-link count-indicator dropdown-toggle"
                    id="notificationDropdown"
                    onClick={() => { handleFilterPopup(true) }}
                    style={{ padding: "0 10px",cursor:"pointer" }} >
                    <i className="mdi mdi-filter" style={{ fontSize: "24px" }}></i>
                    <span className="count-symbol bg-danger"></span>
                  </a>
                  <div
                    className={openFilter ? "show dropdown-menu dropdown-menu-right navbar-dropdown preview-list shadwFilter" : "dropdown-menu dropdown-menu-right navbar-dropdown preview-list shadwFilter "}
                    aria-labelledby="notificationDropdown"
                  >
                    <div className="col-md-12">
                      <div className="row" style={{ justifyContent: "end" }}>
                        <i className="mdi mdi-window-close" style={{ fontSize: "22px", padding: " 0px 10px" }} onClick={() => { handleFilterPopup(false) }}></i>
                      </div>
                    </div>

                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <div className="col-md-12">
                        <div className="toggle" style={{ justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
                          <input type="radio" name="price" value="paid" id="paid" checked={Price == 'paid'} onChange={() => { setPrice('paid') }} />
                          <label for="paid">Paid</label>
                          <input type="radio" name="price" value="free" id="free" checked={Price == 'free'} onChange={() => { setPrice('free') }} />
                          <label for="free">Free</label>
                        </div>
                      </div>
                    </div>

                    {/* <div className="dropdown-item preview-item">
                      <div className="form-group" style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>
                        <select className="form-control form-control-alternative" style={{ border: " 1px solid #2c53a2", color: "#2c53a2", fontSize: "14px" }}
                          onChange={e => setExerciseType(e.target.value)} id="exercisetype" value={ExerciseType} >
                          <option value="0">Select Exercise Type</option>
                          {exercisetypeList.map((option) => (
                            <option key={option?.Lookup_ExerciseTypeId} value={option?.Lookup_ExerciseTypeId}>
                              {i18n.language === "en" ? option.NameEng : option.NameAra}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div> */}

                    <div className="col-md-12">
                      <div className="toggle" style={{ justifyContent: "center", marginTop: "10px", marginBottom: "20px" }}>
                        <input type="radio" name="days" value="today" id="today" checked={SelectedDays == 'today'} onChange={() => { setSelectedDays('today') }} />
                        <label for="today">Today</label>
                        <input type="radio" name="days" value="tomorrow" id="tomorrow" checked={SelectedDays == 'tomorrow'} onChange={() => { setSelectedDays('tomorrow') }} />
                        <label for="tomorrow">Tomorrow</label>
                        <input type="radio" name="days" value="week" id="week" checked={SelectedDays == 'week'} onChange={() => { setSelectedDays('week') }} />
                        <label for="week">Week</label>
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                    <div className="row" style={{ justifyContent: "space-evenly" }}>
                      <button type="button" className="btn btn-outline-primary btn-icon-text" style={{ lineHeight: "1.0", padding: "8px 11px" }} onClick={() => { handleReset() }} >Reset</button>
                      <button type="button" className="btn btn-primary btn-icon-text" style={{ lineHeight: "1.0", padding: "8px 11px" }} onClick={() => { applyFilter() }}>Apply</button>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row" style={{ justifyContent: "center" }}>
                {services && services.length > 0 ? (
                  services?.map((obj, index) => {
                    return (
                      <div className="profile-card js-profile-card" key={index}>
                        <div className="profile-card__img" style={{ margin: "0 auto" }}>
                          <div className="service-item">
                            <img src={obj.Icon ? obj.Icon : img} alt="icon" className="service-icon" />
                          </div>
                        </div>

                        <div
                          className={`profile-card__cnt js-profile-cnt ${lngClass}`}
                        >
                          <div
                            className="profile-card__name"
                            style={{ cursor: "pointer" }}
                          >
                            {i18n.language === "en" ? obj.NameEng : obj.NameAra}
                          </div>
                          {i18n.language === "en" && obj.SubNameEng != null && obj.SubNameEng != "" && <div>{obj.SubNameEng}</div>}
                          {i18n.language === "en" && (obj.SubNameEng == null || obj.SubNameEng == "") && <div>&nbsp;</div>}
                          {i18n.language === "ar" && obj.SubNameAra != null && obj.SubNameAra != "" && <div>{obj.SubNameAra}</div>}
                          {i18n.language === "ar" && (obj.SubNameAra == null || obj.SubNameAra == "") && <div>&nbsp;</div>}
                          <div>
                            {t("Sessions")} - {obj.Sessions}
                          </div>
                          <div className="basic">SAR.{obj.Price}</div>
                        </div>
                        <div className="profile-card-ctr">
                          <div className={`${lngClass} App1`}>
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm"
                              onClick={() => serviceRadio(obj.ServiceId, obj.Price)}
                            >
                              {t("select")}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="empty-state"
                    style={{ backgroundColor: "transparent", paddingTop: "6%" }}
                  >
                    <div className="empty-state__content">
                      <div className="empty-state__icon">
                        <img src={noDataImg} alt="" />
                      </div>
                      <div className="empty-state__message">{t("NoRecordS")}</div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>

          {openPopup && (
            <SportsPopup
              open={openPopup}
              onCloseModal={handleCloseModal}
              service={service}
            />
          )}

          {openSubscribePopup && (
            <SubscribePopup open={openSubscribePopup} onCloseModal={handleCloseModal} />
          )}

        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default ScheduleSports;
