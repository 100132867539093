import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import AppLayout from "./AppLayout";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { API } from "../../Constants/Global";

const ScheduleSports = () => {
  const navigate = useNavigate();
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const [services, setServices] = useState(null);
  const { t, i18n } = useTranslation();
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState(null);
  const [exercisetypeList, setExercisetypeList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const gendrID = user?.GenderId;
  const subscriberId = user?.SubscriberId;
  const [ExerciseType, setExerciseType] = useState(0);
  const [Price, setPrice] = useState(null);
  const [isApplyExerciseType, setIsApplyExerciseType] = useState(false);



  const {
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });

  const serviceId = watch("ServiceID");
  const lng = localStorage.getItem("i18nextLng");

  let config = {
    headers: {
      APICODE: token,
      "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
    },
  };
  useEffect(() => {
    axios
      .get(
        `${API}public/getexcercisetypes`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setExercisetypeList(response.data.Data);
          setIsLoading(false);
        } else {
          setExercisetypeList([]);
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const sort = (value) => {
    const sorted = services?.slice().sort((a, b) => {
      if (value === "1") {
        return a.Price > b.Price ? 1 : -1;
      }
      if (value === "2") {
        return a.Price < b.Price ? 1 : -1;
      }
    });
    setServices(sorted);
  };






  function applyFilterExerciseType() {
    //{item.link_name.replaceAll(' ', '_')}
    navigate("/sports/booking/list/" + ExerciseType);


    // setIsLoading(true);
    // axios
    //   .get(
    //     `${API}service/getservicetypesbyservicetype?ServiceType=Sports&GenderId=${gendrID}&SubscriberId=${subscriberId}&ExerciseType=${ExerciseType}&SelectedDays=&Price=`,
    //     config
    //   )
    //   .then((response) => {
    //     if (response.data.isSuccess) {
    //       setIsApplyExerciseType(true);
    //       setServices(response.data.Data);
    //       setIsLoading(false);
    //     } else {
    //       setServices([]);
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((error) => console.log(error));
  }




  return isLoading ? (
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
                padding: "25px",
              },
              success: {
                duration: 5000,
                theme: {
                  primary: "green",
                  secondary: "white",
                },
              },
              error: {
                duration: 5000,
                theme: {
                  primary: "red",
                  secondary: "black",
                },
              },
            }}
          />
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-heart-pulse"></i>
              </span>{" "}
              {t("sportServices")}
            </h3>
          </div>
          <h4>{t("selectService")}</h4>
          {!isApplyExerciseType && <div>
            <div className="grid-wrapper grid-col-auto text-center" style={{ justifyContent: "center" }}>
              {exercisetypeList && exercisetypeList.length > 0 ? (
                exercisetypeList?.map((obj, index) => {
                  return (
                    <>
                      <label for={'radio-card-' + index} className="radio-card col-">
                        <input type="radio" name="radio-card" id={'radio-card-' + index} onChange={() =>
                           //setExerciseType(obj?.Lookup_ExerciseTypeId)
                           navigate("/sports/booking/list/" + obj?.NameEng?.replaceAll(' ', '_')?.toLowerCase())
                           } />
                        <div className="card-content-wrapper">
                          <span className="check-icon"></span>
                          <div className="card-content">
                            <img
                              src={obj?.Icon}
                              alt="" style={{ width: "100%" }}
                            />
                            <h4> {i18n.language === "en" ? obj.NameEng : obj.NameAra}</h4>

                          </div>
                        </div>
                      </label>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </div>

            {/* <div className="pl-lg-0">
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ textAlign: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-fw"
                    onClick={() => applyFilterExerciseType()}
                  >
                    {t("Continue")}
                  </button>
                </div>
              </div>
            </div> */}

          </div>}


   


        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default ScheduleSports;
