import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import Dashboardlogo from "../../images/Logo1.png";
const Main = () => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  let fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 5000));
  };

  useEffect(() => {
    fakeRequest().then(() => {
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    // <div className="loader-body ng-hide" data-loading="">
    //   <div className="loader"></div>
    // </div>
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <div className="page-header">
            <h3 className="page-title">
              {/* <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-home"></i>
              </span>{" "}
              {t("Dashboard")} */}
            </h3>
            <nav aria-label="breadcrumb"></nav>
          </div>
          <div className="row">
           <div className="col-md-12" style={{textAlign:"center",paddingTop: "50px"}}>
           <img src={Dashboardlogo} style={{width:"25%"}} />
           <h2 style={{color:"#253c78"}}>Subscriber Dashboard</h2>
            </div>
          </div>
          
          
          
          
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Main;
