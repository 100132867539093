import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import authContext from "../../auth-context";
import AppLayout from "./AppLayout";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Loader from "react-js-loader";
import sportsIcon from "../../images/sports.jpg";
import noDataImg from "../../images/no-data.png";
import { API } from "../../Constants/Global";
import { Button } from "@mui/material";
import SportsCalendarPopup from "./SportsCalendarPopup";
const Sports = () => {
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sports, setSports] = useState();
  const [sportsbooking, setSportsbooking] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  const lng = localStorage.getItem("i18nextLng");
  const tkn = localStorage.getItem("token");
  const SubscriberId = JSON.parse(localStorage.getItem("user"))?.SubscriberId;
  let config = {
    headers: {
      APICODE: token,
      "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
    },
  };

  function handleCloseModal(event, data) {
    setOpenPopup(false);
    PageLoad();
  }

  function handleOpenModal() {
    setOpenPopup(true);
  }


  const GetSportsBookingDetails = (obj) => {
    setSportsbooking(obj);
    setOpenPopup(true);
  }


  function PageLoad(){
    setIsLoading(true);
    axios
      .get(
        `${API}sports/getsubscribersports?SubscriberId=${SubscriberId}`,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          setSports(response.data.Data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    PageLoad();

  }, []);

  const handlePayment = (BillId) => {
    var obj =     {
      "Email":JSON.parse(localStorage.getItem("user"))?.Email,
      "FirstName":JSON.parse(localStorage.getItem("user"))?.Name,
      "LastName":JSON.parse(localStorage.getItem("user"))?.Name
  }

    axios
      .post(
        `${API}bill/paymentinitiate?BillId=${BillId}`,
        obj,
        config
      )
      .then((response) => {
        if (response.data.isSuccess) {
          if (response.data.PaymentGatewayInfo != null) {
            if (response.data.PaymentGatewayInfo.result.code === "000.200.100") {
              window.location = response.data.PaymentGatewayUrl;
            }
          }
        } else {
        }
      })
      .catch((error) => console.log(error));
  };


  return isLoading ? (
    <div className="loader-body">
      <div className="circle-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div>
      {token ? (
        <AppLayout>
          <div className="row">
            <div className="col-md-8">
              <div className="page-header">
                <h3 className="page-title">
                  <span className="page-title-icon bg-gradient-primary text-white mr-2">
                    <i className="mdi mdi-dumbbell"></i>
                  </span>{" "}
                  {t("sportsBookings")}
                </h3>
              </div>
            </div>
            <div className="col-md-4" style={{ textAlign: "right" }}>
              <nav aria-label="breadcrumb">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-icon-text"
                  style={{float : lng == 'ar'? 'left' : 'right'}}
                  onClick={() => navigate("/sports/booking")}
                >
                  {t("BookASchedule")}
                </button>
              </nav>
            </div>
          </div>
         
          {sports && sports.length != 0 ? (
            sports?.map((obj, index) => {
              return (
                <div className="row" style={{}}>
                  <div className="col-lg-12">
                    <div className="job-card">
                      <div className={`${lngClass} job-card__content`}>
                        <div className="job-card_img">
                          <img src={sportsIcon} />
                        </div>
                        <div className="job-card_info width25persent">
                          <p className="labelTitle"> {t("ServiceName")} </p>
                          <p
                            className="font-weight-bold"
                            style={{ marginBottom: "0px" }}
                          >
                            {i18n.language === "en"
                              ? obj.ServiceNameEng
                              : obj.ServiceNameAra}
                          </p>
                        </div>

                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("Sessions")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>{obj.ServiceSessions}</p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("BookedSessions")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>
                          {obj?.SelectedClasses != null ? JSON.parse(obj?.SelectedClasses)?.length : 0 }
                            </p>
                        </div>
                        <div className="job-card_info">
                          <p className="labelTitle" style={{ textAlign: "center" }}>
                            {" "}
                            {t("AttendedSessions")}{" "}
                          </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>
                            {obj.AttendedSessions}
                          </p>
                        </div>
                        <div className="job-card_info width0">
                          <p className="labelTitle" style={{ textAlign: "center" }}> {t("Status")} </p>
                          <p className="font-weight-bold" style={{ textAlign: "center" }}>
                            {(() => {
                              switch (obj.ClassStatusId) {
                                case 5:
                                  return (
                                    <span className="badge badge-warning">
                                      Payment Failed
                                    </span>
                                  );
                                case 2:
                                  return (
                                    <span className="badge badge-success">
                                      Paid
                                    </span>
                                  );
                                case 3:
                                  return (
                                    <span className="badge badge-danger">
                                      Cancelled
                                    </span>
                                  );
                                default:
                                  return "---";
                              }
                            })()}
                          </p>
                        </div>
                        <div className="job-card_info" >
                          <div
                            style={{ textAlign: "center", paddingTop: "9px" }}
                          >
                             {(obj.ClassStatusId == 2) && (
                            <button
                              type="link"
                              onClick={() => GetSportsBookingDetails(obj)
                              }
                            >
                              <i className="mdi mdi-calendar-multiple" style={{ fontSize: "30px", color: "#012690" }}></i>{" "}
                            </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="empty-state"
              style={{ backgroundColor: "transparent", paddingTop: "6%" }}
            >
              <div className="empty-state__content">
                <div className="empty-state__icon">
                  <img src={noDataImg} alt="" />
                </div>
                <div className="empty-state__message">{t("NoRecordS")}</div>
              </div>
            </div>
          )}

{openPopup && sportsbooking && (
            <SportsCalendarPopup
              open={openPopup}
              onCloseModal={handleCloseModal}
              sportsbooking={sportsbooking}
            />
          )}
        </AppLayout>
      ) : (
        navigate("/login")
      )}
    </div>
  );
};

export default Sports;
