import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import authContext from "../../auth-context";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import GatePassPopup from "./GatePassPopup";
import { Helmet } from "react-helmet";
import maleIcon from "../../images/male.jpeg";
import femaleIcon from "../../images/female.jpeg";
import { API } from "../../Constants/Global";

const AppLayout = ({ children }) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const { token, userLogin, logout, isLoggedIn } = useContext(authContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const d = new Date();
  //const [openPopup, setQrOpenPopup] = useState(false);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    } else {
      let config = {
        headers: {
          APICODE: token,
          "Accept-Language": i18n.language === "ar" ? "Ar" : "En",
        },
      };
      axios
        .get(`${API}/subscriber/profile`, config)
        .then((response) => {
          if (response.data.isSuccess) {
            localStorage.setItem("user", JSON.stringify(response.data.Data));
            setUser(response.data.Data);
          } else {
            toast.error(response.data.Message);
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, []);

  const lngClass = i18n.language === "en" ? "ltr" : "rtl";
  function handleCloseModal(event, data) {
    setOpenPopup(false);
    //setQrOpenPopup(false);
  }
  // function openCloseMenu() {
  //   var b = document.body.classList.contains('sidebar-icon-only');
  //   console.log(b);
  // }

  function openCloseMenu() {
    var element = document.body;
    element.classList.toggle("sidebar-icon-only");
  }
  // <nav className="sidebar sidebar-offcanvas active" id="sidebar">

  function openCloseMenuMoble() {
    var element1 = document.getElementById("sidebar");
    element1.classList.toggle("active");

    var element2 = document.getElementById("sidebar-list");
    element2.classList.toggle("sideFixed");
  }

  return (
    <div
      style={{
        direction: i18n.language == "en" ? "ltr" : "rtl",
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
            padding: "25px",
          },
          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "green",
              secondary: "white",
            },
          },
          error: {
            duration: 5000,
            theme: {
              primary: "red",
              secondary: "black",
            },
          },
        }}
      />
      <div className={`${lngClass} container-scroller`}>
        <nav
          className={`navbar default-layout-navbar col-lg-12 col-12 p-0  flex-row  margingToppaddingTop fixedTop`}
        >
          <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo" to="/main">
              <img src="/assets/images/LogoEn.png" alt="logo" />
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="/main">
              <img src="/assets/images/logo-mini.png" alt="logo" />
            </Link>
          </div>
          <div
            className="navbar-menu-wrapper d-flex align-items-stretch"
            style={{ backgroundColor: "#eeedf3" }}
          >
            <button onClick={() => openCloseMenu()}
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
            >
              <span className="mdi mdi-menu"></span>

            </button>
            <button type="button" className="btn1 btn-outline-primary1 btn-icon-text1 mobile-scannicon" onClick={() => setOpenPopup(true)}>
              <span className="mdi mdi-qrcode-scan" style={{ fontSize: "33px" }}></span>

            </button>
            <button onClick={() => openCloseMenuMoble()}
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
            >
              <Helmet>
                <script src="/script/jquery-3.2.1.min.js"></script>
              </Helmet>
              <span className="mdi mdi-menu"></span>
            </button>
            <ul className="navbar-nav navbar-nav-right">
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link count-indicator dropdown-toggle"
                  id="notificationDropdown"
                  href="#"
                  data-toggle="dropdown"
                >
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count-symbol bg-danger"></span>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="notificationDropdown"
                >
                  <h6 className="p-3 mb-0">{t("Notifications")}</h6>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-success">
                        <i className="mdi mdi-calendar"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        Event today
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        {" "}
                        Just a reminder that you have an event today{" "}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-warning">
                        <i className="mdi mdi-settings"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        Settings
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        {" "}
                        Update dashboard{" "}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-info">
                        <i className="mdi mdi-link-variant"></i>
                      </div>
                    </div>
                    <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                      <h6 className="preview-subject font-weight-normal mb-1">
                        Launch Admin
                      </h6>
                      <p className="text-gray ellipsis mb-0">
                        {" "}
                        New admin wow!{" "}
                      </p>
                    </div>
                  </a>
                  <div className="dropdown-divider"></div>
                  <h6 className="p-3 mb-0 text-center">
                    {t("SeeAllNotifications")}
                  </h6>
                </div>
              </li> */}
              <li className="nav-item dropdown desktop-swithchbtn">
                <div className="example2" style={{ paddingTop: "17px" }}>
                  <div className="col-sm-4">
                    {i18n.language == "en" && (
                      <button
                        type="button"
                        className="btn-lg btn-toggle"
                        data-toggle="button"
                        aria-pressed="false"
                        autoComplete="off"
                        onClick={() => changeLanguage("ar")}
                      >
                        <div className="handle"></div>
                      </button>


                    )}

                    {i18n.language == "ar" && (
                      <button
                        type="button"
                        className=" btn-lg btn-toggle active"
                        data-toggle="button"
                        aria-pressed="true"
                        autoComplete="off"
                        onClick={() => changeLanguage("en")}
                      >
                        <div className="handle"></div>
                      </button>

                    )}
                  </div>

                </div>
              </li>
            </ul>


          </div>
        </nav>
        <div className={`container-fluid page-body-wrapper ${lngClass}`}>
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav sideFixed" id="sidebar-list">
              <li
                className="nav-item nav-profile"
                style={{ paddingTop: "50px" }}
              >
                <Link to="/profile" className="nav-link">
                  <div className="nav-profile-image">
                    {user?.GenderId == 1 && <img src={maleIcon} />}
                    {user?.GenderId == 2 && <img src={femaleIcon} />}
                    <span className="login-status online"></span>
                  </div>
                  <div className="nav-profile-text d-flex flex-column">
                    <span className="font-weight-bold mb-2">{user?.Name}</span>
                  </div>
                </Link>
              </li>
              {/* <li className="nav-item sidebar-actions">
                <span className="nav-link" style={{textAlign:"center"}}>
                  <button
                    className="btn btn-block btn-lg btn-gradient-primary mt-0" style={{borderRedius:"3px",padding:"7px"}}
                    onClick={() => setOpenPopup(true)}
                  >
                    <i
                      className="mdi mdi-account-card-details"
                    ></i>{" "}
                    
                    {t("GatePass")}
                  </button>
                </span>
              </li> */}
              <li className={'nav-item'} >
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  to="/main"
                >
                  <span className="menu-title">{t("Dashboard")}</span>
                  <i className="mdi mdi-view-dashboard menu-icon"></i>
                </NavLink>
              </li>
              <li className={'nav-item'} >
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  to="/sports"
                >
                  <span className="menu-title">{t("Sports")}</span>

                  <i className="mdi mdi-dumbbell menu-icon"></i>
                </NavLink>
              </li>
              <li className={'nav-item'}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  to="/clinic"
                >
                  <span className="menu-title">{t("Clinic")}</span>

                  <i className="mdi mdi-heart-pulse menu-icon"></i>
                </NavLink>
              </li>
              <li className={'nav-item'}>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "nav-link active" : "nav-link"
                  }
                  to="/events"
                >
                  <span className="menu-title">{t("Events")}</span>

                  <i className="mdi mdi-calendar-multiple menu-icon"></i>
                </NavLink>
              </li>
              <li className={'nav-item'}>

                <NavLink
                  className={
                    (navData) =>
                      navData.isActive ? "nav-link active" : "nav-link"
                  }
                  to="/empowerment"
                >
                  <span className="menu-title">{t("Empowerment")}</span>

                  <i className="mdi mdi-account-multiple-outline menu-icon"></i>
                </NavLink>
              </li>
              <li className="nav-item desktop-scannicon">

                <button type="button" className="" onClick={() => setOpenPopup(true)} style={{ width: "100%", fontWeight: "normal" }}>
                  {/* <span className="menu-title" style={{float: "left"}}>Scan</span>

<           i className="mdi mdi mdi-qrcode-scan menu-icon" style={{float: "right"}}></i> */}

                  <span className="mdi mdi-qrcode-scan" style={{ fontSize: "54px" }}></span>

                </button>

              </li>
              <li className="nav-item dropdown mobile-swithchbtn">
                <div className="example2" style={{ paddingTop: "10px" }}>
                  <div className="col-sm-4">
                    {i18n.language == "en" && (
                      <button
                        type="button"
                        className="btn-lg btn-toggle"
                        data-toggle="button"
                        aria-pressed="false"
                        autoComplete="off"
                        onClick={() => changeLanguage("ar")}
                      >
                        <div className="handle"></div>
                      </button>


                    )}

                    {i18n.language == "ar" && (
                      <button
                        type="button"
                        className=" btn-lg btn-toggle active"
                        data-toggle="button"
                        aria-pressed="true"
                        autoComplete="off"
                        onClick={() => changeLanguage("en")}
                      >
                        <div className="handle"></div>
                      </button>

                    )}
                  </div>

                </div>
              </li>
              <li
                className="nav-item"
                style={{ paddingTop: "15%", backgroundColor: "#ffff" }}
              >
                <button
                  className="nav-link"
                  onClick={() => logout()}
                  style={{ width: "100%" }}
                >
                  <span className="menu-title">{t("Logout")}</span>

                  <i className="mdi mdi-logout menu-icon"></i>
                </button>
              </li>
            </ul>
          </nav>
          <div className="main-panel">
            {openPopup && (
              <GatePassPopup open={openPopup} onCloseModal={handleCloseModal} />
            )}
            <div className="content-wrapper">{children}</div>
            <footer className={`${lngClass} footer`}>
              <div className="container-fluid clearfix">
                <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
                  Copyright © kssc.org.sa {d.getFullYear()}
                </span>
                <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                  {" "}
                  <a href="https://www.ibsksa.com" target="_blank">
                    {t("developedByIBS")}
                  </a>{" "}
                </span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
